import {customTheme} from "@/js/theme";
import {definePreset} from "@primevue/themes";
import Aura from "@primevue/themes/aura";
import PrimeVue from "primevue/config";
import Toast from "primevue/toast";
import ToastService from "primevue/toastservice";
import colors from "tailwindcss/colors";
import {ComponentPublicInstance, createApp} from "vue";

const themeIndex = productType === "ignite" ? 1 : 0;
const TtrSuccessPrimeVueTheme = definePreset(Aura, {
  semantic: {
    primary: customTheme.themes[themeIndex].extend.colors.primary,
    colorScheme: {
      light: {
        surface: colors.neutral,
      },
      dark: {
        surface: colors.neutral,
      },
    },
  },
});

const tempApp = createApp({
  el: "#tempAppElement",
  name: "TtrToast",
  methods: {
    launchToast(message: string, severity: "error" | "success") {
      this.$toast.add({
        severity: severity,
        summary: severity === "error" ? "Error" : "Success",
        detail: message,
        life: 5000,
      });
    },
  },
});

tempApp.use(PrimeVue, {
  theme: {
    preset: TtrSuccessPrimeVueTheme,
    options: {
      darkModeSelector: ".dark",
    },
  },
  ripple: true,
});
tempApp.use(ToastService);
tempApp.component("PToast", Toast);

let vm: ComponentPublicInstance<any> | null = null;

export const launchToast = (message: string, severity: "error" | "success") => {
  //find "main" element and append the tempAppElement to it.
  const appContainer = document.querySelector(".app-container");
  if (appContainer == null) {
    return;
  }

  if (vm == null) {
    //temporary vue app.
    const tempAppElement = document.createElement("div");
    tempAppElement.id = "tempAppElement";
    tempAppElement.innerHTML = '<p-toast position="top-right" style="top: 102px;"></p-toast>';
    appContainer.appendChild(tempAppElement);
    vm = tempApp.mount("#tempAppElement");
  }

  vm.launchToast(message, severity);
};
